<template>
    <BT-Blade-Items
        :bladesData="bladesData"
        :canClose="false"
        :canSearch="false"
        colClass="ma-0 pa-0"
        csvIncludeTitle
        flexColumn
        hideActions
        isChildBlade
        :items="pickingSlip.filteredConsignmentOrderItems"
        :itemsTwo="!pickingSlip.isPacking ? filteredConsignments : null"
        :loadingMsg="loadingMsg"
        :measurements="measurements"
        :onSelect="(bladeData, item) => { selectOrderItem(item) }"
        :onSelectTwo="(bladeData, item) => { selectConsignment(item) }"
        permanent
        :refreshToggle="pickingSlip.refreshToggle"
        :resizable="false"
        :showFilters="false"
        showSettingsTwo
        :small="false"
        :title="pickingSlip.title"
        :actualUsedHeight="184"
        :titleTwo="pickingSlip.currentStockConsignment != null ? pickingSlip.currentStockConsignment.buyer.companyName : null"
        :headers="[
            { text: 'Status', value: 'status', display: true, isIcon: true },
            { text: 'Product', value: 'product', csv: true, title: true, display: true },
            { text: 'Qty', value: 'quantity', csv: true, display: true, csvBreakdown: true },
            { text: 'Assigned', value: 'totalAssigned', csv: true, display: true },
            { text: 'Picked', value: 'totalPicked', csv: true, display: true }]"
        :headersTwo="[
            { text: 'Status', value: 'consign-status', display: true, csv: true, isIcon: true },
            { text: 'CO#', value: 'consignmentNumber', csv: true, subtitle: true, prefix: '#' },
            { text: 'Customer', value: 'buyer.companyName', csv: true, title: true },
            { text: 'Address', value: 'destinationLocation', textFilter: 'toFamiliarLocationLine', csvFilter: 'toLocationLineNoCommas', csv: true },
            { csvText: 'On Order', value: 'consignmentOrderItems', csvBreakdown: true, csv: true, csvArray: true }]">
        
        <template v-slot:bottom="{ measurements }">
            <!-- <v-divider class="my-2" /> -->
            <v-row dense>
                <v-col cols="6" sm="auto">
                    <v-btn v-if="isArrayOfLength(pickingSlip.filteredConsignments, 1)" @click="addProduct">
                        <v-list-item dense>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-icon class="my-auto" left>mdi-plus</v-icon>Product
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-btn>
                    
                    <!-- <v-btn 
                        v-if="isArrayOfLength(pickingSlip.filteredConsignments, 1)"
                        block
                        @click="addProduct">
                        <v-icon class="my-auto" left>mdi-plus</v-icon>Product
                    </v-btn> -->
                </v-col>
                <v-col cols="6" sm="auto">
                    <v-btn v-if="isLengthyArray(pickingSlip.filteredConsignments)" @click="$emit('saveMirror', pickingSlip)" >
                        <v-list-item dense>
                            <v-list-item-content>
                                <v-list-item-title><v-icon small class="my-auto" left>mdi-camera-lock</v-icon>Snapshot</v-list-item-title>
                                <v-list-item-subtitle v-if="pickingSlip.mirror != null"><BT-Relative-Time v-model="pickingSlip.mirror.lastEditedOn" /></v-list-item-subtitle>
                                <!-- <v-list-item-subtitle v-if="pickingSlip.mirror != null">Last: {{ pickingSlip.mirror.lastEditedOn | toLongDateAndTime }}</v-list-item-subtitle> -->
                            </v-list-item-content>
                        </v-list-item>
                    </v-btn>
                    <!-- <v-btn
                        v-if="isLengthyArray(pickingSlip.filteredConsignments)"
                        block
                        @click="$emit('saveMirror', pickingSlip)">
                        <v-list-item-content>
                            <v-list-item-title><v-icon class="my-auto" left>mdi-camera-lock</v-icon>Snapshot</v-list-item-title>
                            <v-list-item-subtitle v-if="pickingSlip.mirror != null">Last: {{ pickingSlip.mirror.lastEditedOn | toLongDateAndTime }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-btn> -->
                </v-col>
                <v-spacer />
                <v-col v-if="isLengthyArray(pickingSlip.filteredConsignmentOrderItems)" cols="12" sm="auto" class="d-flex align-center justify-center">
                    <span class="overline mx-2">QTY: </span>
                    <BT-Increments-Quantity-String
                        :abbreviation="false"
                        :items="pickingSlip.filteredConsignmentOrderItems"
                        :measurements="measurements"
                        spanClass="overline mr-2"
                        valueProp="quantity" />
                </v-col>
                <v-col v-if="isLengthyArray(pickingSlip.filteredConsignmentOrderItems)" cols="12" sm="auto" class="d-flex align-center justify-center">
                    <span class="overline mx-2">Assigned: </span>
                    <BT-Increments-Quantity-String
                        :abbreviation="false"
                        :items="pickingSlip.filteredConsignmentOrderItems"
                        :measurements="measurements"
                        spanClass="overline mr-2"
                        valueProp="totalAssigned" />
                </v-col>
            </v-row>
        </template>
        
        <template v-slot:settingsTwo>
            <v-divider v-if="pickingSlip.canBulk" class="my-2" />
            
            <v-list-item v-if="pickingSlip.canBulk" @click="toggleBulk">
                <v-list-item-icon><v-icon>mdi-swap-horizontal</v-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ pickingSlip.isBulk || pickingSlip.isPacking ? 'Show' : 'Hide' }} Consignments</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider class="my-2" />

            <v-list-item @click="togglePacking">
                <v-list-item-icon><v-icon>mdi-package-variant</v-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ pickingSlip.isPacking ? 'Stop ' : 'Start ' }}Packing</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

        </template>
        
        <template v-slot:settings>
            <v-divider v-if="pickingSlip.canBulk" class="my-2" />
            
            <v-list-item v-if="pickingSlip.canBulk" @click="toggleBulk">
                <v-list-item-icon><v-icon>mdi-swap-horizontal</v-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ pickingSlip.isBulk || pickingSlip.isPacking ? 'Show' : 'Hide' }} Consignments</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item @click="togglePacking">
                <v-list-item-icon><v-icon>mdi-package-variant</v-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ pickingSlip.isPacking ? 'Stop ' : 'Start ' }}Packing</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider class="my-2" />

            <v-list-item v-if="pickingSlip.isTransfer" @click="saveTransferTarget">
                <v-list-item-icon><v-icon>mdi-bullseye-arrow</v-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Resave As Target</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item @click="$emit('saveMirror', pickingSlip)">
                <v-list-item-icon><v-icon>mdi-radar</v-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Track Picking Slip</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item @click="refreshPickingSlip">
                <v-list-item-icon><v-icon>mdi-refresh</v-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Refresh Slip</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item @click="closePickingSlip">
                <v-list-item-icon><v-icon>mdi-close</v-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Close Slip</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider class="my-2" />

            <v-list-item dense @click="showPickingSlipToggle = !showPickingSlipToggle">
                <v-list-item-icon><v-icon>mdi-printer</v-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Print Picking Slip</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item dense @click="showDeliverySlipToggle = !showDeliverySlipToggle">
                <v-list-item-icon><v-icon>mdi-printer</v-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Print Delivery Slips</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item dense @click="generateBoxStickers">
                <v-list-item-icon><v-icon>mdi-printer</v-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Print Box Stickers</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

<!-- :consignmentTextFunction="x => `#${x.stockConsignment.consignmentNumber} - ${x.stockConsignment.buyer.companyName}`" -->
            <BT-Print-Order-Slips
                hideButton
                selectConsignments
                :showToggle="showDeliverySlipToggle"
                :stockConsignments="pickingSlip.filteredConsignments"
                text="Print Delivery Slips" />

            <BT-Print
                :buttonClass="null"
                :getFileName="() => `${pickingSlip.title}.pdf`"
                hide
                :showToggle="showPickingSlipToggle"
                :small="false"
                text="Print Picking Slip"
                title="Print">
                <template>
                    <v-subheader>
                        <h4>{{ pickingSlip.title }}</h4>
                    </v-subheader>
                    <BT-Table
                        v-if="pickingSlip != null"
                        :canRefresh="false"
                        :headers="[
                            { text: 'Product', value: 'product.productName' },
                            { text: 'QTY', value: 'quantity', display: true, align: 'center' },
                            { text: 'Assigned', value: 'totalAssigned', display: true },
                            { text: 'Picked', value: 'totalPicked', display: true }]"
                        hideActions
                        :items="pickingSlip.filteredConsignmentOrderItems">
                        <template v-slot:quantity="{ item }">
                            <BT-Increment-Quantity
                                :measurements="measurements"
                                :productID="item.productID"
                                v-model.number="item.quantity">
                                <template v-slot:append>
                                    <span v-if="item.totalAssigned > item.quantity" class="pl-1">(+{{ item.totalAssigned - item.quantity | toDisplayNumber }})</span>
                                </template>
                            </BT-Increment-Quantity>
                        </template>
                        <template v-slot:totalAssigned="{ item }">
                            <BT-Increment-Quantity
                                :measurements="measurements"
                                :productID="item.productID"
                                v-model.number="item.totalAssigned">
                            </BT-Increment-Quantity>
                        </template>
                        <template v-slot:totalPicked="{ item }">
                            <BT-Increment-Quantity
                                :measurements="measurements"
                                :productID="item.productID"
                                v-model.number="item.totalPicked">
                            </BT-Increment-Quantity>
                        </template>
                    </BT-Table>
                </template>
            </BT-Print>
        </template>
        
        <template v-slot:actionsTwo>
            <v-toolbar-title>Consignments</v-toolbar-title>
            <v-spacer />
        </template>
        
        <template v-slot:actions>
            <v-toolbar-title>{{ bladeTitle }}</v-toolbar-title>
            <v-spacer />
            <v-slide-y-transition>
                <v-btn
                    v-if="!isMobile && pickingSlip.filteredConsignments.some(y => y.isChanged)"
                    class="primary mx-1"
                    @click="savePickingSlip"
                    key="3">
                    <v-icon left>mdi-content-save</v-icon>Save
                </v-btn>
            </v-slide-y-transition>
        </template>

        <template v-slot:listItem="{ item }">
            <v-list-item-icon class="my-auto">
                <v-slide-x-transition>
                    <v-icon v-if="item.totalAssigned >= item.quantity && item.totalAssigned == item.totalPicked" class="success--text">mdi-check-all</v-icon>
                    <v-icon v-else-if="item.totalAssigned >= item.quantity" class="success--text">mdi-check</v-icon>
                    <v-icon v-else-if="item.totalAssigned > 0" class="warning--text">mdi-check</v-icon>
                    <v-icon v-else class="error--text">mdi-close</v-icon>
                </v-slide-x-transition>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    {{ item.product.productName }}
                    <v-slide-x-transition>
                        <v-icon v-if="item.totalAssigned > item.quantity && item.quantity > 0" class="success--text" small>mdi-plus</v-icon>
                    </v-slide-x-transition>
                </v-list-item-title>
                <v-list-item-subtitle>
                    <v-row>
                        <v-col cols="12">
                            <BT-Increment-Quantity
                                :measurements="measurements"
                                prefix="Qty: "
                                :productID="item.productID"
                                v-model.number="item.quantity">
                            </BT-Increment-Quantity>
                        </v-col>
                    </v-row>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <BT-Increment-Quantity
                    :measurements="measurements"
                    prefix="Assign: "
                    :productID="item.productID"
                    :spanClass="item.quantity == item.totalAssigned ? 'success--text' : ''"
                    v-model.number="item.totalAssigned">
                </BT-Increment-Quantity>
                <BT-Increment-Quantity
                    :measurements="measurements"
                    prefix="Pick: "
                    :productID="item.productID"
                    :spanClass="item.totalPicked > item.totalAssigned ? 'error--text' : item.totalAssigned == 0 ? '' : item.totalPicked == item.totalAssigned ? 'success--text' : ''"
                    v-model.number="item.totalPicked">
                </BT-Increment-Quantity>
            </v-list-item-action>
        </template>

        <template v-slot:product="{ item }">
            <v-list-item-title>{{ item.product.productName }}
                <v-slide-x-transition>
                    <v-icon v-if="item.totalAssigned > item.quantity && item.quantity > 0" class="success--text" small>mdi-plus</v-icon>
                </v-slide-x-transition>
            </v-list-item-title>
        </template>

        <template v-slot:itemActionsTwo="{ item }">
            <v-btn icon @click.stop="packConsignment(item)">
                <v-icon>mdi-package-variant</v-icon>
            </v-btn>
        </template>

        <template v-slot:itemActions="{ item }">
            <BT-Increment-Quantity
                :measurements="measurements"
                :productID="item.productID"
                v-model.number="item.quantity">
                <template v-slot:append>
                    <span v-if="item.totalAssigned > item.quantity" class="pl-1">(+{{ item.totalAssigned - item.quantity | toDisplayNumber }})</span>
                </template>
            </BT-Increment-Quantity>
        </template>

        <template v-slot:quantity="{ item }">
            <span v-if="item.mirrorQuantity != null && item.mirrorQuantity != item.quantity" class="primary--text">(<v-icon small class="primary--text">mdi-history</v-icon> {{ item.mirrorQuantity | toDisplayNumber }})</span>
            <BT-Increment-Quantity
                :measurements="measurements"
                :productID="item.productID"
                v-model.number="item.quantity">
                <template v-slot:append>
                    <span v-if="item.totalAssigned > item.quantity" class="pl-1">(+{{ item.totalAssigned - item.quantity | toDisplayNumber }})</span>
                </template>
            </BT-Increment-Quantity>
        </template>
        
        <template v-slot:totalAssigned="{ item }">
            <span v-if="item.mirrorAssigned != null && item.mirrorAssigned != item.totalAssigned" class="primary--text">(<v-icon small class="primary--text">mdi-history</v-icon> {{ item.mirrorAssigned | toDisplayNumber }})</span>
            <BT-Increment-Quantity
                :measurements="measurements"
                :productID="item.productID"
                :spanClass="item.quantity == item.totalAssigned ? 'success--text' : ''"
                v-model.number="item.totalAssigned">
            </BT-Increment-Quantity>
        </template>
        
        <template v-slot:totalPicked="{ item }">
            <span v-if="item.mirrorPicked != null && item.mirrorPicked != item.totalPicked">(<v-icon small class="primary--text">mdi-history</v-icon> {{ item.mirrorPicked | toDisplayNumber }})</span>
            <BT-Increment-Quantity
                :measurements="measurements"
                :productID="item.productID"
                :spanClass="item.totalPicked > item.totalAssigned ? 'error--text' : item.totalAssigned == 0 ? '' : item.totalPicked == item.totalAssigned ? 'success--text' : ''"
                v-model.number="item.totalPicked">
            </BT-Increment-Quantity>
        </template>

        <template v-slot:status="{ item }">
            <v-slide-x-transition>
                <v-icon v-if="item.totalAssigned >= item.quantity && item.totalAssigned == item.totalPicked" class="success--text">mdi-check-all</v-icon>
                <v-icon v-else-if="item.totalAssigned >= item.quantity" class="success--text">mdi-check</v-icon>
                <v-icon v-else-if="item.totalAssigned > 0" class="warning--text">mdi-check</v-icon>
                <v-icon v-else class="error--text">mdi-close</v-icon>
            </v-slide-x-transition>
        </template>

        <template v-slot:consign-status="{ item }">
            <v-slide-x-transition>
                <v-icon v-if="allData.allConsignmentOrderItems.filter(y => y.consignmentID == item.id).every(y => y.totalAssigned == y.quantity && y.totalAssigned == y.totalPicked)" class="success--text">mdi-check-all</v-icon>
                <v-icon v-else-if="allData.allConsignmentOrderItems.filter(y => y.consignmentID == item.id).every(y => y.totalAssigned == y.quantity)" class="success--text">mdi-check</v-icon>
                <v-icon v-else-if="allData.allConsignmentOrderItems.filter(y => y.consignmentID == item.id).some(y => y.totalAssigned > 0)" class="warning--text">mdi-check</v-icon>
                <v-icon v-else class="error--text">mdi-close</v-icon>
            </v-slide-x-transition>
        </template>

    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Picking-Slip-Blade',
    components: {
        BTIncrementQuantity: () => import('~components/BT-Increment-Quantity.vue'),
        BTIncrementsQuantityString: () => import('~components/BT-Increments-Quantity-String.vue'),
        BTPrintOrderSlips: () => import('~components/BT-Print-Order-Slips.vue'),
        BTPrint: () => import('~components/BT-Print.vue'),
        BTTable: () => import('~components/BT-Table.vue'),
    },
    data: function() {
        return {
            showBreakdownDialog: false,
            showPickingSlipToggle: false,
            showDeliverySlipToggle: false,
        }
    },
    props: {
        allData: null,
        bladesData: null,
        loadingMsg: {
            type: String,
            default: null
        },
        measurements: {
            type: Array,
            default: null
        },
        pickingSlip: null,
    },
    computed: {
        bladeTitle() {
            if (this.pickingSlip == null) {
                return '';
            }
            if (this.pickingSlip.isBulk) {
                return this.pickingSlip.title;
                //return this.isMobile ? this.pickingSlip.title : 'Items';
            }
            else {
                if (this.pickingSlip.consignmentID == null) {
                    return 'Unknown';
                }
                else {
                    var e = this.pickingSlip.filteredConsignments.find(x => x.id == this.pickingSlip.consignmentID);
                    return e != null ? e.buyer.companyName : 'Unknown';
                }
            }
        },
        filteredConsignments() {
            return !this.pickingSlip.isBulk && this.pickingSlip.consignmentIDs.length > 1 ? this.pickingSlip.filteredConsignments : null;
        },
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        }
    },
    mounted() {
        this.selectConsignment(null, this.pickingSlip);
    },
    methods: {
        async addProduct() {
            if (this.pickingSlip != null && this.isArrayOfLength(this.pickingSlip.filteredConsignments, 1)) {
                var existingIDs = this.pickingSlip.filteredConsignmentOrderItems.map(y => y.productID);
                var newProduct = await this.$selectItem({
                    navigation: 'products',
                    itemText: 'productName',
                    onFilter: list => list.filter(x => !existingIDs.some(c => c == x.id))
                });

                if (newProduct != null) {
                    this.allData.allConsignmentOrderItems.push({
                        consignmentID: this.pickingSlip.filteredConsignments[0].id,
                        id: null,
                        productID: newProduct.id,
                        product: newProduct,
                        quantity: 0,
                        totalAssigned: 0,
                        totalPicked: 0
                    });

                    this.pickingSlip.filteredConsignmentOrderItems.push({
                        productID: newProduct.id,
                        product: newProduct,
                        quantity: 0,
                        totalAssigned: 0,
                        totalPicked: 0
                    });
                }
            }
        },
        closePickingSlip() {
            this.$emit('close', this.pickingSlip);
        },
        async generateBoxStickers() {
            const xl = require('excel4node');
            let wb = new xl.Workbook();

            var excessIDs = this.pickingSlip.filteredConsignments.map(x => x.id);
            var cList = [];

            if (this.isLengthyArray(excessIDs)) {
                try {
                    do {
                        var ids = excessIDs.slice(0, excessIDs.length > 20 ? 20 : excessIDs.length);
                        
                        var results = this.copyDeep(await this.$BlitzIt.store.getAll('customer-consignments', { ids: ids.toString(), includeDetails: true }));
                        
                        results.forEach(consignment => {
                            cList.push(consignment);
                        });

                        excessIDs = excessIDs.filter(x => !ids.some(y => y == x));
                    } while (this.isLengthyArray(excessIDs))
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
            }

            var ws = wb.addWorksheet('Sheet 1', {
                sheetFormat: {
                    defaultColWidth: 18,
                    defaultRowHeight: 266
                }
            });
            var cStyle = wb.createStyle({
                alignment: {
                    horizontal: 'center',
                    textRotation: 90,
                    vertical: 'center',
                    wrapText: true
                },
                font: {
                    bold: true,
                    size: 18
                }
            });

            var col = 1;

            this.pickingSlip.filteredConsignments.forEach(stockConsignment => {
                var custConsignment = cList.find(x => x.id == stockConsignment.id);
                if (custConsignment != null) {
                    var str = `${stockConsignment.buyer.companyName}\n${stockConsignment.destinationLocation.streetNumber} ${stockConsignment.destinationLocation.streetName}\n${stockConsignment.destinationLocation.suburb} ${stockConsignment.destinationLocation.state} ${stockConsignment.destinationLocation.postcode}\n`;
                    var ii = custConsignment.packagedItems.sum(x => x.quantity);
                    var i = 0;
                    do {
                        var st = `${str}${i + 1} of ${ii}`;
                        ws.cell(1, col)
                            .string(st)
                            .style(cStyle);

                        col += 1;
                        i += 1;
                    } while(i < ii)
                }
            });

            var buff = await wb.writeToBuffer();
            this.generateFile(buff, 'stickers.xlsx');

        },
        refreshPickingSlip() {
            this.$emit('refresh', this.pickingSlip);
        },
        packConsignment(stockConsignment) {
            this.pickingSlip.isPacking = true;
            this.selectConsignment(stockConsignment);
        },
        savePickingSlip() {
            this.$emit('save', this.pickingSlip);
        },
        saveTransferTarget() {
            this.$emit('save', this.pickingSlip, true);
        },
        selectOrderItem(orderItem) {
            this.$emit('selectOrderItem', orderItem, this.pickingSlip);
        },
        selectConsignment(stockConsignment) {
            this.$emit('selectConsignment', stockConsignment != null ? stockConsignment.id : null, this.pickingSlip);
        },
        togglePacking() {
            this.pickingSlip.isPacking = !this.pickingSlip.isPacking;
            this.pickingSlip.isBulk = !this.pickingSlip.isPacking;
            this.selectConsignment(null);
        },
        toggleBulk() {
            if (this.pickingSlip.isBulk || this.pickingSlip.isPacking) {
                this.pickingSlip.isBulk = false;
                this.pickingSlip.isPacking = false;
            }
            else {
                this.pickingSlip.isBulk = true;
                this.pickingSlip.isPacking = false;
            }
            
            this.selectConsignment(null);
        }
    }
}
</script>